import { WOOD_CODE } from 'server/wood-code';

export const codeTrans = {
	[WOOD_CODE.OK]: '成功',
	[WOOD_CODE.STOCK_NOT_FOUND]: '库存未找到',
	[WOOD_CODE.INSERT_TRANSACTION_FAILED]: '生成送货单失败',
	[WOOD_CODE.UPDATE_STOCK_AT_TRANSACTION_FAILED]:
		'在送货单操作中，更新库存失败',
	[WOOD_CODE.NOTHING_CHANGE]: '无更改',
	[WOOD_CODE.INSERT_STOCK_FAILED]: '增加库存失败',
	[WOOD_CODE.REPEAT_STOCK]: '重复库存，请检查',
};
